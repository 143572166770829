<template>
    <div>
      <el-select
        :value="selected"
        @input="dispatch"
        :placeholder="placeholder || $t('message.workMeetingType')"
        filterable
        clearable
        :size="size"
        class="w-100"
      >
        <el-option
          v-for="(meeting_type, index) in workMeetingType"
          :key="'workMeetingType-' + index"
          :label="meeting_type.name"
          :value="meeting_type.id"
        ></el-option>
      </el-select>
    </div>
  </template>
  <script>
  import { mapGetters, mapActions } from "vuex";
  import { i18n } from "@/utils/i18n";
  export default {
    props: {
      size: {
        default: "small",
      },
      placeholder: {
        default: null,
      },
      id: {
        default: null,
      },
    },
    watch: {
      id: {
        handler: function () {
          this.selected = this.id;
        },
        immediate: true,
      },
    },
    data() {
      return {
        selected: null,
      };
    },
    mounted() {
      if (this.workMeetingType && this.workMeetingType.length === 0) this.updateInventory();
    },
    computed: {
      ...mapGetters({
        workMeetingType: "workMeetingType/inventory",
      }),
    },
    methods: {
      ...mapActions({
        updateInventory: "workMeetingType/inventory",
      }),
      dispatch(e) {
        this.$emit("input", e);
        this.selected = e;
      },
    },
  };
  </script>
  