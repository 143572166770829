<template>
	<div>
		<el-row :gutter="20">
			<el-col  :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<el-calendar @input="handleClickCalendar">
					<template
						slot="dateCell"
						slot-scope="{date, data}">
						<!-- <div class="custom-date-cell"> -->
							<p :class="isDateChecked(date) ? 'is-selected' : ''">
								{{ data.day.split('-').slice(1).join(' - ') }} {{ isDateChecked(date) ? '✔️' : '' }}
							</p>
						<!-- </div> -->
					</template>
				</el-calendar>
			</el-col>
			<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
				<!-- if workMeeting periodic -->
				<div v-if="object.visible">
					<el-row :gutter="10" class="mb-4">
						<el-col :span="8">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">{{$t('message.periodicity')}}</span>
								<el-checkbox 
									v-if="object.visible" 
									v-model="object.periodic_checked" 
									:label="$t('message.periodicity')" 
									size="medium"
									border
									class="w-100"
									>
								</el-checkbox>
							</div>
						</el-col>
						<el-col :span="16" v-if="object.periodic_checked">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">{{ $t('message.end_date_of_periodicity') }}</span>
								<el-date-picker
									class="w-100"
									size="medium"
									v-model="object.periodic_end_date"
									type="date"
									:placeholder="$t('message.end_date_of_periodicity')"
									:format="date_format"
									:value-format="date_format"
								>
								</el-date-picker>
							</div>
						</el-col>
					</el-row>

					<el-row :gutter="10" v-if="object.periodic_checked">
						<el-col :span="12">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">время начала собрания</span>
								<el-time-select
									class="w-100"
									placeholder="Время"
									v-model="object.start_date_time"
									:picker-options="{
										start: '08:30',
										step: '00:15',
										end: '18:30'
									}"
								>
                    	 	</el-time-select>
							</div>	
						</el-col>

						<el-col :span="12">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">время  завершение собрания</span>
								<el-time-select
									class="w-100"
									placeholder="Время"
									v-model="object.end_date_time"
									:picker-options="{
										start: '08:30',
										step: '00:15',
										end: '18:30'
									}"
								>
                    	 	</el-time-select>
							</div>	
						</el-col>
					</el-row>

					<el-row :gutter="10" v-if="object.periodic_checked">
						<el-col :span="12">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">{{ $t('message.quantity') }} {{ $t('message.periodicity') }}</span>
								<el-input-number 
									class="w-100"
									v-model="object.periodic_count"
									:min="1" 
									:max="5"
									@change="checkMaxCount"
									@keypress.native="onlyNumber"
								>
								</el-input-number>
							</div>
						</el-col>
					</el-row>
				</div>

				<!-- if not workMeeting periodic -->
				<div v-else>
					<el-row :gutter="10" v-for="(item, index) in object.selectedDates" :key="'item-'+index" class="d-flex justify-center align-center">
						<el-col :span="4">
							<b>{{ item.date }}</b>
						</el-col>
						<el-col :span="10">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">время начала собрания</span>
								<el-time-select
									class="w-100"
									placeholder="Время"
									v-model="item.start_date_time"
									:picker-options="{
										start: '08:30',
										step: '00:15',
										end: '18:30'
									}"
								>
                    	 	</el-time-select>
							</div>	
						</el-col>
						<el-col :span="10">
							<div class="app-form__group mb-4">
								<span class="input--label d-block mb-2">время  завершение собрания</span>
								<el-time-select
									class="w-100"
									placeholder="Время"
									v-model="item.end_date_time"
									:picker-options="{
										start: '08:30',
										step: '00:15',
										end: '18:30'
									}"
								>
                    	 	</el-time-select>
							</div>	
						</el-col>
					</el-row>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'Calendar',
	data(){
		return {
			object: {
				selectedDates: [],
				periodic_checked: false,
				visible: true,
				start_date: '',
				periodic_end_date: '',
				start_date_time: '',
				end_date_time: '',
				periodic_count: null,
			}
		}
	},
	watch:{
		'object.selectedDates': {
			handler: function(newVal){
				if (newVal.length > 1) {
				   this.object.visible = false;
					this.object.periodic_checked = false;
					this.object.periodic_end_date = '';
					this.object.start_date_time = '';
					this.object.end_date_time = '';
					this.object.periodic_count = null;
				}
				else {
					this.object.visible = true;
				}
			},
			deep: true
		},
		'object.periodic_checked': {
			handler: function(newVal){
				if (!newVal){
					this.object.periodic_end_date = '';
					this.object.start_date_time = '';
					this.object.end_date_time = '';
					this.object.periodic_count = null;
				}
			},
			deep: true
		}
	},
	methods: {
		handleClickCalendar(date){
			let formatted_date = this.formatDate(date);
			let has_item = false;

			if (!_.isEmpty(this.object.selectedDates)){
				if (_.find(this.object.selectedDates, item => (item.date == formatted_date))){
					let index = _.findIndex(this.object.selectedDates, item => (item.date == formatted_date));
					this.object.selectedDates.splice(index, 1);

					has_item = true;
				}
			}

			if (!has_item && this.object.selectedDates.length < 5) {
				this.object.selectedDates.push(
					{
						date: formatted_date,
						is_checked: true,
						start_date_time: '',
						end_date_time: ''
					}
				);
			}
		},
		isDateChecked(date){
			let current_date = this.formatDate(date);
			if (_.find(this.object.selectedDates, item => (item.date == current_date)))
				return true;
			else
				return false;
		},
		checkMaxCount(count){
			if (count > 5) {
				return 5;
			}
		},
		onlyNumber(e){
			let char = String.fromCharCode(e.keyCode);
			if (char.replace(/[^0-9]/g, '')) {
				return true;
			}
			else e.preventDefault();
		},
		clearObject(){
			this.object.selectedDates = [];
			this.object.periodic_checked = false;
			this.object.visible = true;
			this.object.start_date = '';
			this.object.periodic_end_date = '';
			this.object.start_date_time = '';
			this.object.end_date_time = '';
			this.object.periodic_count = null;
		}
	}
}
</script>

<style>
	.is-selected {
		color: #1989FA;
	}
	.custom-date-cell {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 30px;
	border-radius: 50%;
	}
</style>