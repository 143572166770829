<template>
    <div class="">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div :class="mode ? 'content__titleday' : 'content__titlenight'"
                                class="content-title d-flex align-center mr-2"> {{ $t("message.workMeeting") }} </div>
                            <div class="block-sarche d-flex">
                                <div class="header__search mr-4">
                                    <crm-input :size="'small'" :class="mode ? 'input__day' : 'input__night'"
                                        :className="'w100'" v-model="filterForm.search"
                                        :icon="'el-icon-search'"></crm-input>
                                </div>
                                <div>
                                    <el-checkbox v-model="filterForm.status_archived" :label="$t('message.archived')" border
                                        size="medium"></el-checkbox>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">

                        <crm-create-and-column-settings :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'" @c-create="drawerCreate = true"
                            :columns="columns" @c-change="updateColumn">
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <div class="card-table-header table-crm-smart">
                <table class="table-my-code table-bordered" :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData">
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.user_id.show">
                                {{ columns.user_id.title }}
                            </th>

                            <th v-if="columns.meeting_goal.show">
                                {{ columns.meeting_goal.title }}
                            </th>

                            <th v-if="columns.type_id.show">
                                {{ columns.type_id.title }}
                            </th>

                            <th v-if="columns.detail.show">
                                {{ columns.detail.title }}
                            </th>

                            <th v-if="columns.begin_date.show">
                                {{ columns.begin_date.title }}
                            </th>

                            <th v-if="columns.end_date.show">
                                {{ columns.end_date.title }}
                            </th>

                            <th v-if="columns.filial.show">
                                {{ columns.filial.title }}
                            </th>

							<th v-if="columns.department.show">
                                {{ columns.department.title }}
                            </th>

                            <th v-if="columns.meeting_format.show">
                                {{ columns.meeting_format.title }}
                            </th>

                            <th v-if="columns.link.show">
                                {{ columns.link.title + ' Google Meet' }}
                            </th>

                            <th v-if="columns.comment.show">
                                {{ columns.comment.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <el-input clearable size="mini" :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.id" :placeholder="columns.id.title" class="id_input"></el-input>
                            </th>

                            <th v-if="columns.user_id.show">
                                <crm-input :placeholder="columns.user_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.user_id"></crm-input>
                            </th>

                            <th v-if="columns.meeting_goal.show">
                                <crm-input :placeholder="columns.meeting_goal.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.meeting_goal"></crm-input>
                            </th>

                            <th v-if="columns.type_id.show">
                                <crm-input :placeholder="columns.type_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.type_id"></crm-input>
                            </th>

                            <th v-if="columns.detail.show">

                            </th>

                            <th v-if="columns.begin_date.show">
                                <crm-date-picker :placeholder="columns.begin_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.begin_date"></crm-date-picker>
                            </th>

                            <th v-if="columns.end_date.show">
                                <crm-date-picker :placeholder="columns.end_date.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.end_date"></crm-date-picker>
                            </th>

                            <th v-if="columns.filial.show">
                                <select-filial :placeholder="columns.filial.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.filial_id"></select-filial>
                            </th>

							<th v-if="columns.department.show">
                                <select-department :placeholder="columns.department.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.department_id"></select-department>
                            </th>

                            <th v-if="columns.meeting_format.show">
                                <el-select class="w-100" v-model="filterForm.meeting_format"
                                    :placeholder="$t('message.meeting_format')" clearable size="mini">
                                    <el-option :label="$t('message.online')" :value="'online'"></el-option>
                                    <el-option :label="$t('message.offline')" :value="'offline'"></el-option>
                                </el-select>
                            </th>

                            <th v-if="columns.link.show">
                            </th>

                            <th v-if="columns.comment.show">
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"></crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"></crm-date-picker>
                            </th>

                            <th class="settinW" v-if="columns.settings.show"></th>
                        </tr>
                    </thead>

                    <transition-group name="flip-list" tag="tbody">
                        <tr v-for="meeting_type in list" :key="meeting_type.id" class="cursor-pointer">

                            <td v-if="columns.id.show">
                                {{ meeting_type.id }}
                            </td>

                            <td v-if="columns.user_id.show">
                                {{ meeting_type.user ? meeting_type.user.name : '' }}
                            </td>

                            <td v-if="columns.meeting_goal.show">
                                {{ meeting_type.meeting_goal }}
                            </td>

                            <td v-if="columns.type_id.show">
                                {{ meeting_type.type ? meeting_type.type.name : '' }}
                            </td>

                            <td v-if="columns.detail.show">
                                <router-link tag="div" :to="'/workMeetingInfo/' + meeting_type.id">
                                    <el-button type="success" size="mini">{{ $t('message.view') }}</el-button>
                                </router-link>
                            </td>

                            <td v-if="columns.begin_date.show">
                                {{ meeting_type.begin_date }}
                            </td>

                            <td v-if="columns.end_date.show">
                                {{ meeting_type.end_date }}
                            </td>

                            <td v-if="columns.filial.show">
                                {{ meeting_type.filials ? meeting_type.filials.name : '' }}
                            </td>

							<td v-if="columns.department.show">
                                {{ meeting_type.departments ? meeting_type.departments.name : '' }}
                            </td>

                            <td v-if="columns.meeting_format.show">
                                {{ meeting_type.meeting_format ? $t('message.' + meeting_type.meeting_format) : '' }}
                            </td>

                            <td v-if="columns.link.show">
                                <div v-if="meeting_type.link_google_meet">
                                    <el-button type="primary" @click="copyURL(meeting_type.link_google_meet)"
                                        icon="el-icon-copy-document" class="copy-link" plain size="mini">
                                        {{ $t('message.copyURL') }}
                                    </el-button>
                                </div>
                                <div v-else>
                                    {{ $t('message.no') + ' ' + $t('message.link') }}
                                </div>
                            </td>

                            <td v-if="columns.comment.show">
                                {{ meeting_type.comment }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ meeting_type.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ meeting_type.updated_at }}
                            </td>

                            <td v-if="columns.settings.show" class="settings-td">

                                <crm-settings :name="$options.name" :model="meeting_type" :actions="actions"
                                    :permissionShow="'workMeetings.update'" :permissionDestroy="'workMeetings.delete'"
                                    @edit="edit" @delete="destroy" @archivate="archivate"></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination @c-change="updatePagination" :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"></crm-pagination>
                    <!-- <Pagination /> -->
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer :with-header="false" :visible.sync="drawerCreate" ref="drawerCreate" size="70%"
                    @opened="drawerOpened('drawerCreateChild')" @closed="drawerClosed('drawerCreateChild')">
                    <div>
                        <crm-create ref="drawerCreateChild" drawer="drawerCreate"> </crm-create>
                    </div>
                </el-drawer>

                <el-drawer :with-header="false" :visible.sync="drawerUpdate" size="70%" ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')" @closed="drawerClosed('drawerUpdateChild')">
                    <crm-update :selectedItem="selectedItem" ref="drawerUpdateChild" drawer="drawerUpdate"></crm-update>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
import list from "@/utils/mixins/list";
import Pagination from "@/components/el-pagination";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import { mapGetters, mapActions } from "vuex";
import SelectFilial from '@/components/inventory/select-filial';
import SelectDepartment from '@/components/inventory/select-department';

export default {
    name: "workMeeting",
    mixins: [list],
    components: {
        Pagination,
        CrmCreate,
        CrmUpdate,
        SelectFilial,
        SelectDepartment,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "workMeeting/list",
            columns: "workMeeting/columns",
            pagination: "workMeeting/pagination",
            filter: "workMeeting/filter",
            sort: "workMeeting/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete", "archivate"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "workMeeting/index",
            setPagination: "workMeeting/setPagination",
            updateSort: "workMeeting/updateSort",
            updateFilter: "workMeeting/updateFilter",
            updateColumn: "workMeeting/updateColumn",
            updatePagination: "workMeeting/updatePagination",
            show: "workMeeting/show",
            empty: "workMeeting/empty",
            delete: "workMeeting/destroy",
            refreshData: "workMeeting/refreshData",
            archivateWorkMeeting: "workMeeting/archivateWorkMeeting"
        }),
        async copyURL(link_google_meet) {
            try {
                await navigator.clipboard.writeText(link_google_meet);
                this.$message({
                    message: this.$t('message.linkCopied'),
                    type: 'success'
                });
            } catch ($e) {
                alert('Cannot copy');
            }
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('workMeeting/EMPTY_LIST');
        next()
    },
};
</script>

