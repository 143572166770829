<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{ $t("message.new_m", { m: $t("message.workMeeting") }) }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->
      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <el-form-item
                      prop="meeting_goal"
                      :label="$t('message.meeting_goal')"
                      >
                        <el-input
                            :placeholder="$t('message.meeting_goal')"
                            v-model="form.meeting_goal"
                            size="large"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                      <el-form-item  :label="$t('message.workMeetingType')" prop="type_id">
                            <select-meeting-type
                                v-model="form.type_id"
                                :id="form.type_id"
                                :placeholder="$t('message.workMeetingType')"
                                size="large"
                            ></select-meeting-type>
                        </el-form-item>
                    </el-col>
                     <!-- end-col -->
                </el-row>
                <el-row :gutter="20">
					<el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item  :label="$t('message.filials')" prop="filial_ids">
                            <multi-select-filials
                                v-model="form.filial_ids"
                                :filial_ids="form.filial_ids"
                                size="large"
                            ></multi-select-filials>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->
                    <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <el-form-item  :label="$t('message.responsible')">
                            <multi-select-users
                                v-model="form.responsible_ids"
                                :user_ids="form.responsible_ids"
                                :placeholder="$t('message.responsible')"
                                size="large"
                                :collapse_tags="true"
										  :filial_ids="form.filial_ids"
                            ></multi-select-users>
                        </el-form-item>
                    </el-col>
                     <!-- end-col -->
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<el-form-item :label="$t('message.departments')" class="label_mini">
							<!-- <select-department
								v-model="form.department_id"
								:id="form.department_id"
								:filial_ids="form.filial_ids"
								:size="'large'"
							>
							</select-department> -->
							<multi-select-departments
								v-model="form.department_ids"
								:id="form.department_ids"
								:filial_ids="form.filial_ids"
								:size="'large'"
							>
							</multi-select-departments>
						</el-form-item>
					</el-col>
                </el-row>
    
                <el-row :gutter="20">
                  <el-divider content-position="left">Дата и время собрания</el-divider>
						<div>
							<crm-calendar ref="calendar"></crm-calendar>
						</div>
						
                  <!-- <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item   label="Дата">
                      <el-date-picker
                        class="w-100"
                        v-model="form.start_date.date"
                        type="date"
                        :format="date_format"
                        :value-format="date_format"
                        :placeholder="$t('message.date')">
                      </el-date-picker>
                    </el-form-item>
                  </el-col> -->
                  <!-- end-col -->

                  <!-- <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item   label="Время">
                      <el-time-select
                        class="w-100"
                        placeholder="Время"
                        v-model="form.start_date.time"
                        :picker-options="{
                          start: '08:30',
                          step: '00:15',
                          end: '18:30'
                        }">
                      </el-time-select>
                    </el-form-item>
                  </el-col> -->
                  <!-- end-col -->
                </el-row>

                <!-- <el-row :gutter="20">
                  <el-divider content-position="left">Дата и время  завершение собрания</el-divider>
                  <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item  label="Дата">
                      <el-date-picker
                        class="w-100"
                        v-model="form.finish_date.date"
                        type="date"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :placeholder="$t('message.date')">
                      </el-date-picker>
                    </el-form-item>
                  </el-col>


                  <el-col  :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item  label="Время">
                      <el-time-select
                        class="w-100"
                        placeholder="Время"
                        v-model="form.finish_date.time"
                        :picker-options="{
                          start: '08:30',
                          step: '00:15',
                          end: '18:30',
                          minTime: form.begin_date.time
                        }">
                      </el-time-select>
                    </el-form-item>
                  </el-col>
              
                </el-row> -->

					 <el-row :gutter="20">
						 <el-col  :xs="24" :sm="24" :md="24" :lg="6" :xl="6">
                    <el-form-item  :label="$t('message.meeting_format')" class="mt-1 mb-0 pb-0">
								<el-select class="w-100" v-model="form.meeting_format" :placeholder="$t('message.meeting_format')">
									<el-option :label="$t('message.online')" :value="'online'"></el-option>
									<el-option :label="$t('message.offline')" :value="'offline'"></el-option>
								</el-select>
                    </el-form-item>
                  </el-col>
                  <el-col  :xs="24" :sm="24" :md="24" :lg="18" :xl="18">
                    <el-form-item  :label="$t('message.link') + ' Google Meet'" class="mt-1 mb-0 pb-0">
                      <el-input
                        :placeholder="$t('message.link') + ' Google Meet'"
                        v-model="form.link_google_meet">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item  :label="$t('message.comment')" class="mt-1 mb-0 pb-0">
                      <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Please input"
                        v-model="form.comment">
                      </el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-divider content-position="left">{{ $t('message.notifications_nn') }}</el-divider>
                  <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item class="mt-1 mb-0 pb-0">
                      <el-switch
                        v-model="form.notify"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                      </el-switch>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="form.notify" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item  :label="$t('message.notificationTypes')" prop="notification_type_id">
                      <select-notification-types 
                        v-model="form.notification_type_id"
                        :size="'large'"
                        :id="form.notification_type_id">
                      </select-notification-types>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="form.notify" :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                    <el-form-item class="mt-0 pt-0" :label="'Напомнить за ' + (form.notification_time ? form.notification_time : '') + ' минут раньше'">
                      <el-select  v-model="form.notification_time" placeholder="минут" class="w-100">
                        <el-option
                          v-for="item in minutes"
                          :key="item.value"
                          :label="item.label + ' минут раньше'"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <!-- end-col -->
                </el-row>

                <!-- FILE UPLOAD START -->
                <div class="my-new-card-style">
                    <div class="u-clearfix mt-2">
                        <el-divider content-position="left">{{$t('message.attachments')}}</el-divider>
                        <el-row :gutter="20" class="p-2" v-if="updateFile && updateFile.length > 0">
                            <el-col :span="12" v-for="(file, index) in updateFile" :key="file.uid">
                                <div class="mb-2 d-flex" style="border: 1px solid #dee3e7;" :class="(file.name === form.cover_image) ? 'mm-image_covered' : ''">
                                    <div class="mm-image-container" @click="showImage(file)">
                                        <el-image v-if="['image/png', 'image/jpeg'].includes(file.type)"
                                            style="max-width:100%; max-height:100%; position: relative; top: 50%; transform: translateY(-50%);"
                                            class="p-1"
                                            :src="file.url"
                                            fit="fill">
                                        </el-image>
                                        <div v-if="!['image/png', 'image/jpeg'].includes(file.type)" class="text-center">
                                            <i class="el-icon-document-copy mt-2" style="font-size: 50px;"></i>
                                            <span class="d-block mt-2 font-weight-bold" >{{/[^.]*$/.exec(file.name)[0]}}</span>
                                        </div>
                                    </div>
                                    <div class="ml-2" >
                                        <h3>{{fileNameTruncate(file.name)}}</h3>
                                        <div class="d-flex">
                                            <a :href="file.url" :download="file.name" class="mr-3">
                                                <el-button type="primary"  icon="el-icon-download" size="medium"></el-button>
                                            </a>
                                            <el-button @click="removeImage(index, updateFile)" class="mr-3" type="danger"  icon="el-icon-delete-solid" size="medium">
                                            </el-button>
                                        </div>
                                    </div>

                                </div>
                            </el-col>
                        </el-row>

                        <div class="d-flex mb-3">
                            <el-upload
                                v-model="form.files"
                                class="upload-demo wu-100 "
                                action="/"
                                :limit="10"
                                :accept="fileAccepts"
                                :on-exceed="handleExceed"
                                :on-change="createImageList"
                                :on-remove="handleRemove"
                                :auto-upload="false"
                                :show-file-list="false"
                                >
                                <el-button> {{ $t('message.upload_file') }}</el-button>
                            </el-upload>
                        </div>
                    </div>
                </div>  
                <!-- FILE UPLOAD END -->

                <el-row :gutter="20">
                  <el-col  :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <div class="gc-card__body  mb-3">
                      <div class="text-descr mb-0">
                        <span class="text-descr__label d-block">{{ $t("message.responsible") }}</span>
                        <div
                          class="
                            responsible-block
                            d-flex
                            align-center
                            font-medium
                            p-2
                            rounded-sm
                          "
                        >
                          <div
                            class="gc-small-block rounded-circle hidden mr-3"
                            style="border: 1px solid gray"
                          >
                            <img
                              :src="
                                authUser
                                  ? (authUser.avatar
                                    ? authUser.avatar
                                    : '/img/default-user-icon.png')
                                  : '/img/default-user-icon.png'
                              "
                              alt="Default Avatar"
                              class="d-block"
                            />
                          </div>
                          <div class="f-fill">
                            <span class="text-descr__text f-fill">{{
                              authUser.name
                            }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-descr mb-0">
                        <span class="text-descr__label d-block">{{ $t('message.participants') }}</span>

                        <div class="p-relative">
                          <div class="responsible-block  d-flex align-center  p-2  font-medium rounded-sm pointer">
                            <div
                              class="gc-small-block rounded-circle hidden mr-3"
                              @click="getSelectedUsers"
                            >
                              <img
                                src="/img/default-user-icon.png"
                                alt="Default Avatar"
                                class="d-block"
                              />
                            </div>
                            <div
                              class="f-fill d-flex align-center"
                              @click="getSelectedUsers"
                            >
                              <span class="text-descr__text f-fill"
                                >{{ $t('message.add_participant') }}</span
                              >
                              <i class="el-icon-plus ml-auto"></i>
                            </div>
                          </div>
                          <div class="responsible-block-dropdown aer  p-relative w-100 rounded  p-3">
                            <div class="responsible-block-search">
                              <div class="app-form__group">
                                <div class="responsible-block-input">
                                  <input
                                    type="text"
                                    class="gc-country-input"
                                    :placeholder="$t('message.search')"
                                    @input="findResponsibleUsers"
                                    ref="responsibleUsersInput"
                                  />
                                </div>
                              </div>
                            </div>

                            <div
                              class="responsible-users-dropdown rounded p-3 mt-2"
                              ref="usersDropdown"
                            >
                              <perfect-scrollbar style="height: 200px">
                                <div
                                  v-for="user in usersList"
                                  :key="user.id"
                                  @click="selectUsers(user)"
                                  class="
                                    users-dropdown-items
                                    d-flex
                                    p-2
                                    rounded
                                    transition
                                  "
                                >
                                  <div class="user-items-left mr-2">
                                    <div
                                      class="
                                        user-item-avatar
                                        w-100
                                        h-100
                                        rounded-pill
                                        hidden
                                      "
                                    >
                                      <img
                                        :src="
                                          user
                                            ? (user.avatar
                                              ? user.avatar
                                              : '/img/default-user-icon.png')
                                            : '/img/default-user-icon.png'
                                        "
                                        alt
                                      />
                                    </div>
                                  </div>
                                  <div class="user-items-right">
                                    <h4 class="my-1">
                                      {{ user.name }}
                                    </h4>
                                    <p class="m-0">
                                      {{ user.phone }}
                                    </p>
                                  </div>
                                </div>
                              </perfect-scrollbar>

                              <div class="custom__button mt-3">
                                <el-button
                                  @click="setUsers"
                                  type="primary"
                                  icon="el-icon-user"
                                  size="medium"
                                  > {{$t('message.add')}} </el-button
                                >
                              </div>
                            </div>

                            <div
                              v-for="user in selectedUsersList"
                              :key="user.id"
                              class="users-dropdown-items d-flex p-2 rounded transition"
                            >
                              <div class="user-items-left mr-2">
                                <div
                                  style="border: 1px solid gray"
                                  class="user-item-avatar w-100 h-100 rounded-pill hidden"
                                >
                                  <img
                                    :src="
                                      user
                                        ? (user.avatar
                                          ? user.avatar
                                          : '/img/default-user-icon.png')
                                        : '/img/default-user-icon.png'
                                    "
                                    alt
                                  />
                                </div>
                              </div>
                              <div class="user-items-right">
                                <h4 class="my-1">
                                  {{ user.name }}
                                </h4>
                                <p class="m-0">
                                  {{ user.phone }}
                                </p>
                              </div>
                              <div
                                class="f-fill d-flex align-center"
                                @click="removeFromSelectedUsers(user)"
                              >
                                <i class="el-icon-delete icon__delete ml-auto"></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        
                      </div>
                    </div>
                  </el-col>
                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
      <el-dialog
          v-if="selectedImage.name"
          :title="selectedImage.name"
          :append-to-body="true"
          custom-class="mm-picture-show"
          :visible.sync="dialogPictureVisible">
            <div ref="ImageContainer" class="text-center w-100">
                <el-image v-if="['image/png', 'image/jpeg'].includes(selectedImage.type) || (selectedImage.raw && ['image/png', 'image/jpeg'].includes(selectedImage.raw.type))"
                style="object-fit: contain;"
                class="p-1"
                :src="selectedImage.url"
                fit="fill">
                </el-image>
            </div>
        </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import workMeeting from "@/utils/mixins/models/workMeeting";
import crmCalendar from './calendar/crm-calendar';

export default {
   name: "workMeeting",
   mixins: [form, drawer, workMeeting],
	components:{crmCalendar},
	data(){
		return {}
	},
   methods: {
      ...mapActions({
         save: "workMeeting/store",
      }),
		afterOpen(){
			if (this.model != null) {           
				this.form = JSON.parse(JSON.stringify(this.model));
				if(!this.form.user_id){
					this.form.user_id = this.authUser.id;    
				}   
      	}
		},
		submit(close = true) {
			let calendar = this.$refs['calendar'].object;

			if (calendar.periodic_checked && !_.isEmpty(calendar.selectedDates)) {
				this.form.periodic_checked = calendar.periodic_checked;
				this.form.periodic_count = parseInt(calendar.periodic_count);
				this.form.periodic_end_date  = calendar.periodic_end_date;

				this.form.begin_date = calendar.selectedDates[0].date + ' ' + calendar.start_date_time;
				this.form.end_date = calendar.selectedDates[0].date + ' ' + calendar.end_date_time;
			}
			else {
				if (!_.isEmpty(calendar.selectedDates)){
					this.form.periodic_checked = calendar.periodic_checked;
					this.form['selectedDates'] = calendar.selectedDates.map(item => {
						return {
							begin_date: item.date + ' ' + item.start_date_time,
							end_date: item.date + ' ' + item.end_date_time
						}
					});
				}
			}

			this.form.user_id = this.authUser.id;
			this.form.participant_ids = this.selectedUsersList.length > 0 ? this.selectedUsersList.map(el => el.id) : [];
		
			if(!this.form.notify){
			  this.form.notification_time = '';
			}
			this.$refs["form"].validate((valid) => {
				if (valid) {
					let formData = new FormData();
					for (const key in this.form) {
						if (key != "filial_ids" && key != "department_ids" && key != "responsible_ids" && key != "participant_ids" 
							&& key != "status_archived" && key != "periodic_checked" && key != "selectedDates") {
						if (this.form[key]) {
							formData.append(key, this.form[key]);
						} else {
							formData.append(key, "");
						}
						}else{
						formData.append(key, JSON.stringify(this.form[key]));
						}
					}
					
					if (_.size(this.updateFile) > 0) {
						for (const key in this.updateFile) {
							if (this.updateFile.hasOwnProperty(key)) {
								const element = this.updateFile[key];
								formData.append(`files[${key}]`, element);
							}
						}
					}
				
					this.loadingButton = true;
					this.save(formData)
						.then((res) => {
							this.loadingButton = false;
							this.$alert(res);
							this.parent().listChanged();
							this.$refs['calendar'].clearObject();
							if (close) this.close();
						})
						.catch((err) => {
							this.loadingButton = false;
							this.$alert(err);
						});
				}
			});
	   },
   },
};
</script>
<style>
 
</style>
